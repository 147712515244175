import React from "react";
import './styles/Footer.css';

export class Footer extends React.Component {
  render() {
    return (
      <>
        <div className="break_line margin_top break_line_footer"></div>
        <div className="footer">
          <div className="links">
            <a href="https://github.com/YunCYang" className="menu_link">
              <i className="fa-brands fa-github"></i>
            </a>
            <a href="https://www.linkedin.com/in/yun-c-yang/" className="menu_link">
              <i className="fa-brands fa-linkedin-in"></i>
            </a>
            <a href="https://codepen.io/Redlark" className="menu_link">
              <i className="fa-brands fa-codepen"></i>
            </a>
          </div>
          <div className="note">Yun C Yang 2022</div>
        </div>
      </>
    );
  }
}

// top about workk contact
