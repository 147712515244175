export const projects = {
   work:[
      {
         id: 0,
         name: "Sudo-Ku",
         live: "https://sudo-ku.yuncyang.com/",
         src: "https://github.com/YunCYang/sudo-ku",
         tags: [
            "React",
            "Jest",
            "Sass"
            ],
         images: [
            "./images/sudo-ku.png",
            "./images/sudo-ku_title.png",
            "./images/sudo-ku_game.png"
            ],
         desc: "A sudoku game that recursively creates puzzle",
         detail: "Sudo-Ku is a classical sudoku game providing 5 levels of difficulty. The puzzles are generated recursively and guaranteed to have a solution. Player can switch modes between actually putting in answers and taking notes within the block with ease."
      },
      {
         id: 1,
         name: "LoR Deck Name Editor",
         live: "https://decknameeditor.yuncyang.com/",
         src: "https://github.com/YunCYang/lor_deck_title_generator",
         tags: [
            "React",
            "Node",
            "TypeScript"
            ],
         images: [
            "./images/dne.png",
            "./images/dne_preview.png"
            ],
         desc: "A deck name editor for the game Legend of Runeterra developed with React TypeScript",
         detail: "LoR Deck Name Editor allows user to easily generates stylized deck title without the knowledge programming. The user can also preview the generated deck title and copy the created result."
      },
      {
         id: 2,
         name: "Lacerna",
         live: "https://lacerna.yuncyang.com/",
         src: "https://github.com/YunCYang/lacerna",
         tags: [
            "React",
            "Node",
            "PostgreSQL",
            "Redux"
            ],
         images: [
            "./images/lacerna.png",
            "./images/lacerna_product.png",
            "../images/lacerna_cart.png"
            ],
         desc: "A demo e-commerce site developed with ReactJs",
         detail: "Lacerna is a demo e-commerce site selling coats, capes and similar apparels. Lacerna mimics real-life online stores with home page promoting new and popular products, product page that lets you see product detail, and cart page allowing you to proceed with the order. User can sign up as a user or shop as a guest."
      },
      {
         id: 3,
         name: "Iron-Journal",
         live: "https://iron-journal.yuncyang.com/",
         src: "https://github.com/YunCYang/iron-journal",
         tags: [
            "React",
            "Node",
            "Sass",
            "Jest"
            ],
         images: [
            "./images/iron-journal_title.png",
            "./images/iron-journal_character.png"
            ],
         desc: "A web assistant for table RPG IronSworn",
         detail: "An assistant for people who enjoys the RPG game Ironsworn and who also wish they can store the information about their characters online. User can both create an account, or user the website as a guest."
      }
   ]
};
