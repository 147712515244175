import React from "react";
import { projects } from '../data/work';
import './styles/Work.css';

interface Project {
  id: number,
  name: string,
  live: string,
  src: string,
  images: string[]
  desc: string,
  detail: string
}

export class Work extends React.Component {
  renderList = (project: Project): JSX.Element => {
    return (
      <div className="work_card" key={project.id}>
        {/* {console.log(project.images[0] === "../images/sudo-ku.png")} */}
        <h3>{project.name}</h3>
        <div className="work_image" style={{backgroundImage: `url(${project.images[0]})`}}>
          <a href={project.live}> </a>
        </div>
        <p>{project.desc}</p>
      </div>
    );
  }

  render() {
    return (
      <div className="work">
        <div className="work_container">
          {projects.work.map((project: Project) => this.renderList(project))}
        </div>
      </div>
    );
  }
}
