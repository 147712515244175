import React from "react";
import { Routes, Route, Link } from "react-router-dom";

import './styles/Content.css';
import { About } from "./About";
import { Work } from "./Work";
import { Contact } from "./Contact";

export class Content extends React.Component {
  render() {
    return (
      <div className="content">
        <div className="break_line margin_top"></div>
        <div className="menu_bar">
          <div className="about">
            <Link to="/" className="link_about menu_link">
              About
            </Link>
          </div>
          <div className="work">
            <Link to="/work" className="link_work menu_link">
              Work
            </Link>
          </div>
          <div className="contact">
            <Link to="/contact" className="link_contact menu_link">
              Contact
            </Link>
          </div>
        </div>
        <div className="break_line margin_top"></div>
        <div className="content_content">
          <Routes>
            <Route path="/" element={ <About /> } />
            <Route path="/work" element={ <Work /> } />
            <Route path="/contact" element={ <Contact /> } />
          </Routes>
        </div>
      </div>
    );
  }
}
