import React from "react";
import { Link } from "react-router-dom";
import './styles/Header.css';

interface HeaderProps {
  setLight: Function
};
interface SwitchClass {
  switch_class: string
};

export class Header extends React.Component<HeaderProps, SwitchClass> {
  constructor(props: HeaderProps) {
    super(props);

    this.state = {
      switch_class: 'lightOn'
    };
  }

  lightSwitch = (): void => {
    // const element = document.getElementsByClassName('light_switch')[0];
    // element.classList.remove('switch_animation')
    const currentClass = this.state.switch_class;
    const newClass = currentClass === 'lightOn' ? 'lightOff' : 'lightOn';

    this.setState({
      switch_class: newClass
    });

    this.props.setLight({ light: newClass });
  }

  render() {
    return (
      <div className="header">
        <div className="headbar">
          <div className="name">
            <Link to="/" className="menu_link">Yun C Yang</Link>
            <div className={`light_switch ${this.state.switch_class}`}
            onClick={this.lightSwitch}>
              <div className="mask"></div>
            </div>
          </div>
          {/* <div className="switch_container">
            <div className="switch_frame">
              <div className="toggle_switch">
                <div className="mask"></div>
              </div>
            </div>
          </div> */}
          {/* <div className="switch">
            <div className="day">
              <div></div>
              <i className="fa-solid fa-sun"></i>
            </div>
            <div className="night">
              <div></div>
              <i className="fa-solid fa-moon"></i>
            </div>
          </div> */}
        </div>
        <div className="break_line margin_top"></div>
        <div className="head_content">
          {/* <div className="decoration_line head_deco_1">
            <div className="deco_1"></div>
            <div className="deco_2"></div>
          </div> */}
          <div className="content1">Hi, I am Yun</div>
          <div className="content2">I do web development and design</div>
          <div className="content3">You can find me climbing rocks at other times</div>
          {/* <div className="decoration_line head_deco_2">
            <div className="deco_1"></div>
            <div className="deco_2"></div>
          </div> */}
        </div>
      </div>
    );
  }
}

/*
hi, i am a web enthusiast
i do front end / back end / javascript / typescript / react / redux / web development / software
you can find me climbing rocks at other times
*/
