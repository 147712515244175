import React from 'react';
import { BrowserRouter } from "react-router-dom";
import './styles/general.css';
import './styles/App.css';

import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';

interface BgLight {
  light: string;
}

export class App extends React.Component<{}, BgLight> {
  constructor(props: {}) {
    super(props);

    this.state = {
      light: ''
    };

    this.setState = this.setState.bind(this);
  };

  render() {
    return (
      <div className={`container ${this.state.light ? this.state.light : 'lightOn'}`}>
        <BrowserRouter>
          <Header setLight={this.setState} />
          <Content />
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}
