import React from "react";
import './styles/About.css';

export class About extends React.Component {
  render() {
    return (
      <div className="about">
        <div className="text_container text_1">
          <p>Hi, it's great to see you here.</p>
          <p>I'm Yun, a web designer that strives
            to constantly improve myself, and make
            the world a little bit better at the same time.</p>
          <p>I am proficient in JavaScript, React,
            TypeScript, and other related skills
            such as Redux and Router. I am also
            experienced with PostGreSQL and Express / Axios
            for building and connecting with my backend.</p>
        </div>
        <div className="image_container img_1">
          <img src={require("../images/171022tp.jpg")} alt="Scenery of where I grew up" />
        </div>
        <div className="text_container text_2">
          <p>Check out my work if you want to take
            a deeper look at what I've done before.
            You are also welcome to ask me anything
            if you are intereting.</p>
          <p>While I am away from the screen,
            I like to drive to SoCal desert and boulder.</p>
        </div>
        <div className="image_container img_2">
          <img src={require("../images/190407jtca.jpg")} alt="Scenery of Joshua Tree" />
        </div>
      </div>
    );
  }
}
