import React from "react";
import './styles/Contact.css';

export class Contact extends React.Component {
  render() {
    return (
      <div className="contact">
        <h3>Contact</h3>
        <a href="mailto:yuncyang20@gmail.com" className="menu_link">Email</a>
        <a href="https://github.com/YunCYang" className="menu_link">Github</a>
        <a href="https://www.linkedin.com/in/yun-c-yang/" className="menu_link">LinkedIn</a>
        <a href="https://codepen.io/Redlark" className="menu_link">CodePen</a>
      </div>
    );
  }
}
